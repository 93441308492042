import React from "react";
import "./brochures.css";
function Brochures() {
  return (
    <div>
      <div className="brochures-header seven">
        <h2 className="text-center py-5 brochures-title">Brochures</h2>
      </div>
    </div>
  );
}

export default Brochures;
