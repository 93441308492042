import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import "./contactus.css";

function ContactUs() {
  const captchaRef = useRef(null)
  function sendEmail(e) {
    e.preventDefault();
    // const token = captchaRef.current.getValue();
    // captchaRef.recaptcha.execute();
    if(captchaRef.current.getValue()){
      emailjs
      .sendForm(
        "service_smtp",
        "template_3sbleul",
        e.target,
        "qjWL4E310QZpvJxwd"
      )
      .then(
        (result) => {
          result = Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          captchaRef.current.reset();
          e.target.reset();
        },
        (error) => {
          error = Swal.fire({
            icon: "error",
            title: "Opps. Please Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Verify Captcha",
        showConfirmButton: false,
        timer: 1500,
      });
  }
}
  return (
    <section>
      <div className="contact-bg">
        <h1 className="text-center page-title">Contact Us</h1>
      </div>

      <div className="mt-5">
        <div className="container">
          {/* <p className="mml-title text-center mb-5">Get In Touch</p> */}
          <div className="row">
            <div className="col-md-4 p-3">
              <div className="text-center">
                <i
                  className="fas fa-map-marker-alt fa-3x"
                  style={{ color: "#50577A" }}
                ></i>
                <div>
                  <p className="contactus-text mt-3 mx-3">
                    <b>
                      Flat 3/A, House 287, Road 19C, <br />
                      DOHS Mohakhali, Dhaka-1206, Bangladesh
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 p-3">
              <div className="text-center">
                <i
                  className="fas fa-phone-alt fa-3x"
                  style={{ color: "#50577A" }}
                ></i>
                <div>
                  <p className="contactus-text mt-3 mx-3">
                    <b>
                      Land Phone:{" "}
                      <a href="tel:+880248811048" className="get-in-touch">
                        +88 02 48811048
                      </a>
                      <br />
                      Cell Phone:{" "}
                      <a href="tel:+8801321120451" className="get-in-touch">
                        +88 01321-120451
                      </a>
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 p-3">
              <div className="text-center">
                <i
                  className="fas fa-envelope fa-3x"
                  style={{ color: "#50577A" }}
                ></i>
                <div>
                  <p className="contactus-text mt-3 mx-3">
                    <b>
                      <a className="get-in-touch" href="mailto:info@mmlbd.com">
                        info@mmlbd.com
                      </a>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="container">
          <h2 className="mml-title text-center mb-5">Messages Us</h2>

          <form onSubmit={sendEmail}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name">
                    {" "}
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    name="full_name"
                    required
                  />
                </div>
                <br />

                <div className="form-group">
                  <label htmlFor="email">
                    {" "}
                    Email <small className="text-danger">*</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email"
                    name="email"
                    required
                  />
                </div>
                <br />

                <div className="form-group">
                  <label htmlFor="phone">
                    {" "}
                    Phone Number <small className="text-danger">*</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    aria-describedby="phone"
                    placeholder="Enter Phone Number"
                    name="phone_number"
                    required
                  />
                </div>
                <br />

                <div className="form-group">
                  <label htmlFor="org-name">
                    {" "}
                    Organization Name <small className="text-danger">*</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="org-name"
                    name="organization_name"
                    placeholder="Enter Oraganization Name"
                  />
                </div>
                <br />
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label className="name">
                    {" "}
                    Position <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="position"
                    placeholder="Enter Position Name"
                  />
                </div>
                <br />
                <div className="form-group">
                  <label className="name">
                    {" "}
                    Subject <small className="text-danger">*</small>
                  </label>
                  <select
                    className="form-control"
                    id="name"
                    name="subject"
                    placeholder="Subject Line"
                  >
                    <option defaultValue disabled>
                      Choose One Option
                    </option>
                    <option value="Genarel Query">Genarel Query</option>
                    <option value="Product Inquery">Product Inquery</option>
                    <option value="Servicesy">Services</option>
                    <option value="Rental">Rental</option>
                    <option value="Geological Consultancy">
                      Geological Consultancy
                    </option>
                  </select>
                </div>
                <br />
                <div className="form-group">
                  <label className="name">
                    {" "}
                    Message <small className="text-danger">*</small>
                  </label>
                  <textarea
                    className="form-control"
                    id="name"
                    name="message"
                    placeholder="Your Message"
                    rows="5"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="pb-3 text-center w-100">
            <ReCAPTCHA
            ref={captchaRef}
            sitekey = "6LdmhlAlAAAAADd8C12r8AKd1LbzZvg98_MrAFC0"
            />
            </div>
            <div className="pb-3 text-center">
              <button className="btn btn-primary button-style" type="submit">
                <span>Send Message</span>
                {/* <div className="wave"></div> */}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div>
        <h1 className="mml-title text-center mt-5">Get Deriction From Here</h1>
        <div className="pt-3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1825.478455226895!2d90.39289639814756!3d23.784548698017367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1404066de0b425a6!2sMoving%20Machines%20Limited!5e0!3m2!1sen!2sbd!4v1677423617242!5m2!1sen!2sbd"
            width="100%"
            height="250"
            style={{ border: 0, padding: 0, margin: 0 }}
            allowFullScreen=""
            title="Google Map Location"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
